:root {
    --rbro-primary-text-color: #191919;
    --rbro-active-button-color: #191919;
    --rbro-mainPanel-bg-color: #3B3B3B;
    --rbro-mainPanel-logo-bg-color: #3B3B3B;
    --rbro-mainPanel-button-color: #F33198;
    --rbro-mainPanel-button-hover-color: #FF63B6;
    --rbro-mainPanel-text-color: #FFFFFF;
    --rbro-mainPanel-item-color: #C5C5C5;
    --rbro-detailPanel-button-color: #3CC4FF;
    --rbro-detailPanel-button-hover-color: #009EE2;
    --rbro-detailPanel-focus-color: #3CC4FF;
    --rbro-detailPanel-section-color: rgba(59,59,59,0.2);
    --rbro-detailPanel-section-hover-color: rgba(59,59,59,0.15);
    --rbro-detailPanel-sectionHeader-color: #191919;
    --rbro-popup-main-color: #3CC4FF;
    --rbro-popup-item-bg-color: #3CC4FF;
    --rbro-popup-item-alt-bg-color: #0177A9;
    --rbro-popup-item-text-color: #FFFFFF;
    --rbro-popup-description-color: #8C8C8C;
    --rbro-popup-content-border-color: #191919;
    --rbro-error-text-color: #ff3b30;
}

.rbroClassicTheme {
    --rbro-primary-text-color: #444444;
    --rbro-active-button-color: #004864;
    --rbro-mainPanel-bg-color: #004864;
    --rbro-mainPanel-logo-bg-color: #C5C5C5;
    --rbro-mainPanel-button-color: #336D83;
    --rbro-mainPanel-button-hover-color: #FDC700;
    --rbro-mainPanel-text-color: #F1F3F4;
    --rbro-mainPanel-item-color: #DDDDDD;
    --rbro-detailPanel-button-color: #004864;
    --rbro-detailPanel-button-hover-color: #FDC700;
    --rbro-detailPanel-focus-color: #004864;
    --rbro-detailPanel-section-color: #D4E6F4;
    --rbro-detailPanel-section-hover-color: rgba(113,171,218,0.2);
    --rbro-detailPanel-sectionHeader-color: #004864;
    --rbro-popup-main-color: #004864;
    --rbro-popup-item-bg-color: #D4E6F4;
    --rbro-popup-item-alt-bg-color: #B2E6D1;
    --rbro-popup-item-text-color: #444444;
    --rbro-popup-description-color: #666666;
    --rbro-popup-content-border-color: #444444;
}

/* normalizing styles */
#reportbro * {
    outline: none;
}

#reportbro {
    font-family: 'Open Sans', sans-serif;
    text-rendering: optimizelegibility;
    outline: none;
    color: var(--rbro-primary-text-color);
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    min-width: 1140px; /* prevent menu item wrap on too small screens */
    height: 100%;
    top: auto;
    left: auto;
    -webkit-user-select: none;
    user-select: none;
}

/* Main content container - general styles */

.rbroContainer {
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    width: 100%;
    overflow: hidden;
    top: 45px;
    bottom: 0;
    left: 0;
    background-color: #E4E7EA;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.rbroContainer > div {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
}

.rbroContainer input, .rbroContainer select, .rbroContainer textarea {
    color: var(--rbro-primary-text-color);
    width: 100%;
    box-sizing: border-box;
    height: 28px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.1);
    background-color: #F1F3F4;
    transition: all 0.2s linear;
    padding: 0 4px;
    margin: 0;
    outline: none;
    font-size: 12px;
    font-weight: inherit;
    box-shadow: none;
}

.rbroContainer select {
    transition: none;
}

.rbroContainer input[type=checkbox],
.rbroPopupWindow input[type=checkbox] {
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
    display: inline-block;
    opacity: inherit;
}

.rbroContainer input[type=file],
.rbroPopupWindow input[type=file] {
    color: transparent;
    padding: 0;
    border: none;
    background-color: transparent;
    height: auto;
    max-width: 120px;
}

.rbroContainer textarea {
    resize: none !important;
    min-height: 34px;
    padding: 5px;
}

.rbroContainer input:focus, .rbroContainer select:focus, .rbroContainer textarea:focus {
    background: #FFFFFF;
    border: 1px solid var(--rbro-detailPanel-focus-color);
    outline: none;
}

.rbroContainer input[disabled], .rbroContainer select[disabled], .rbroContainer textarea[disabled] {
    background: transparent;
    color: #8C8C8C;
}

/* Menu panel */

.rbroMenuPanel {
    background-color: #FFFFFF;
    position: absolute;
    width: 100%;
    height: 45px;
    top: 0;
    left: 0;
    font-size: 15px;
    z-index: 1;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.rbroMenuPanel > div {
    position: absolute;
    top: 0;
}

.rbroLogo {
    position: absolute;
    left: 0;
    top: 0;
    width: 230px;
    background-image: url("rb_logo_white.png");
    background-repeat: no-repeat;
    height: 45px;
    background-size: auto 85%;
    background-position: center;
    background-color: var(--rbro-mainPanel-logo-bg-color);
    z-index: 10;
}

.rbroClassicTheme .rbroLogo {
    background-image: url("rb_logo_dark.png");
}

.rbroToolButtonContainer {
    left: 230px;
}

.rbroElementButtonContainer {
    left: 620px;
    width: calc(100% - 630px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rbroElementButtons {
    height: 45px;
}

.rbroActionButtons > div, .rbroElementButtons > div {
    display: inline-block;
}

.rbroZoom {
    margin: 4px;
}

.rbroZoom .rbroZoomLevel {
    font-size: 11px;
    vertical-align: middle;
    width: 32px;
    display: inline-block;
    text-align: right;
}

.rbroZoomButton.rbroRoundButton {
    margin: 0 0 0 4px;
}

.rbroZoomButton.rbroRoundButton:not([disabled]) {
    color: var(--rbro-primary-text-color);
    background-color: #E4E7EA;
}

.rbroZoomButton.rbroRoundButton:not([disabled]):hover {
    background-color: rgba(0,0,0,0.15);
}

/* Sidebar menu enabled  */

.rbroMenuPanelSidebar .rbroMainPanel {
    height: calc(100% - 45px);
}

.rbroMenuPanelSidebar .rbroLogo {
    top: auto;
    bottom: 0;
    background-color: var(--rbro-mainPanel-logo-bg-color);
}

.rbroMenuPanelSidebar .rbroDocumentPanel {
    right: 92px;
    padding: 10px 0;
}

.rbroMenuPanelSidebar .rbroMenuPanel {
    right: 0;
    top: 0;
    left: auto;
    width: 92px;
    height: 100%;
    padding: 2px;
    box-sizing: border-box;
}

.rbroMenuPanelSidebar .rbroMenuPanel > div {
    position: relative;
    left: 0;
}

.rbroMenuPanelSidebar .rbroToolButtonContainer {
    display: block;
}

.rbroMenuPanelSidebar .rbroElementButtonContainer {
    display: block;
    width: auto;
}

.rbroMenuPanelSidebar .rbroElementButtons {
    border-top: 1px solid #E4E7EA;
    border-bottom: 1px solid #E4E7EA;
    margin-top: 5px;
    margin-bottom: 10px;
    height: auto;
}

.rbroMenuPanelSidebar .rbroMenuButton {
    border: none;
}

.rbroMenuPanelSidebar .rbroActionButtons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.rbroMenuPanelSidebar .rbroActionButtons .rbroActionButton {
    display: block;
    margin: 4px 1px;
}

.rbroMenuPanelSidebar .rbroZoom {
    margin: 4px 0;
}

.rbroMenuPanelSidebar .rbroZoomButton {
    display: inline-block;
}

.rbroMenuPanelSidebar .rbroContainer {
    top: 0;
}

.rbroMenuPanelSidebar .rbroDetailPanel {
    margin-top: 0;
}

.rbroMenuPanelSidebar .rbroGridButton {
    display: block;
}

/* Main Panel - Navigation */

.rbroMainPanel {
    left: 0;
    background-color: var(--rbro-mainPanel-bg-color);
    width: 230px;
    color: var(--rbro-mainPanel-text-color);
}

.rbroMainPanel ul, .rbroMainPanel li {
    list-style: none inside;
    padding: 0;
    margin: 0;
}

.rbroMainPanel > ul > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    line-height: 20px;
}

.rbroMainPanel > ul > li > ul > li:not(:empty) {
    line-height: 20px;
}

.rbroMainPanel > div:hover {
    background-color: rgba(116, 116, 130, 0.2);
}

.rbroMenuItem {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
    cursor: pointer;
    text-transform: uppercase;
    gap: 5px;
}

.rbroMenuItem.rbroUnusedParameter {
    background-color: #191919;
}

.rbroMenuItem.rbroUnusedParameter:hover {
    background-color: rgba(34, 34, 34,0.8);
}

.rbroMenuItem > div {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
}

.rbroMenuItem > div > span {
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.rbroMenuItem:hover {
    background-color: rgba(116, 116, 130, 0.2);
}

.rbroMenuItem.rbroMenuItemActive {
    background-color: #FFFFFF;
    color: var(--rbro-primary-text-color);
}

.rbroMenuItem.rbroError {
    background-color: #FFCCCC;
    color: var(--rbro-primary-text-color);
}

.rbroMenuItem.rbroMenuItemActive.rbroError {
    background-color: #FFFFFF;
    color: var(--rbro-error-text-color);
}

.rbroMenuItem.rbroMenuItemDragOver {
    border-bottom: 3px solid #6CDF86;
}

ul ul .rbroMenuItem {
    padding: 2px 5px;
    font-size: 14px;
    text-transform: none;
    color: var(--rbro-mainPanel-text-color);
}

ul ul ul .rbroMenuItem {
    padding: 2px 5px 2px 12px;
    color: var(--rbro-mainPanel-item-color);
}

ul ul ul ul .rbroMenuItem {
    padding: 2px 5px 2px 20px;
}

ul ul ul ul ul .rbroMenuItem {
    padding: 2px 5px 2px 30px;
}

ul ul ul ul ul ul .rbroMenuItem {
    padding: 2px 5px 2px 40px;
}

ul ul .rbroMenuItemText {
    width: calc(100% - 56px);
}

.rbroMenuArrow {
    font-size: 12px;
    margin-right: 2px;
    transition:  all 0.2s linear;
    margin-left: 2px;
}

.rbroMenuItem.rbroMenuItemOpen .rbroMenuArrow {
    transform: rotate(90deg);
}

.rbroMenuItemNoChildren .rbroMenuArrow {
    display: none;
}

.rbroMainPanelSizer {
    width: 3px;
    cursor: ew-resize;
    background: #C5C5C5;
    transition: background 0.2s linear;
}

.rbroMainPanelSizer:hover {
    background: #009EE2;
}

/* Detail Panel */

.rbroDetailPanel {
    width: 390px;
    background-color: #FFFFFF;
    font-size: 13px;
    margin-top: 2px;
    border-right: 2px solid #E4E7EA;
}

.rbroEmptyDetailPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.rbroEmptyDetailPanel .rbroLogo {
    background-image: url("rb_logo_dark.png");
    width: 390px;
    height: 70px;
    background-size: auto 80%;
    opacity: 0.08;
    position: relative;
    background-color: transparent;
}

.rbroFormRowContainer { /* groups multiple rbroFormRow without borders within the group */
    border-bottom: 1px solid #E4E7EA;
}

.rbroFormRowContainer .rbroFormRow {
    border: none;
}

.rbroFormRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #E4E7EA;
}

.rbroFormRow label {
    width: 35%;
    cursor: pointer;
}

.rbroFormRow label.rbroDisabled {
    color: #C5C5C5;
}

.rbroFormField {
    width: 65%;
}

.rbroFormField:not(.rbroSplit) > :nth-child(n+2) { /* add some top margin in case of multiline formField */
    margin-top: 5px;
}

.rbroSplit, .rbroTripleSplit { /* splitting a formField */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rbroSplit .rbroErrorMessage {
    width: 100%;
}

.rbroSplit input {
    width: 49%;
}

.rbroTripleSplit input, .rbroTripleSplit select {
    width: 32%;
}

.rbroSelector textarea, .rbroSelector input {
    width: 85%;
    font-family: 'Open Sans', sans-serif;
}

.rbroSmallInput input {
    width: 70px;
}

.rbroInfo {
    font-size: 85%;
}

.rbroInfo a, .rbroInfo a:hover, .rbroInfo a:visited, .rbroInfo a:focus, .rbroInfo a:active {
    color: var(--rbro-detailPanel-button-color);
    text-decoration: none;
}

.rbroInfo a:hover {
    color: var(--rbro-detailPanel-button-hover-color);
}

.rbroImageFile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}


/* Rich Text Editor */

.rbroFormRow.rbroRichTextEditor {
    flex-direction: column;
    -webkit-user-select: auto;
    user-select: auto;
}

/* For styling font selection only */
.rbroSelectFont select:first-child {
    width: 70%;
}

.rbroSelectFont select:nth-child(2) {
    width: 22%;
}

.rbroPanelSection, .rbroPanelSection.rbroPanelSectionHeaderOpen:hover {
    justify-content: center;
    cursor: pointer;
    background-color: var(--rbro-detailPanel-section-color);
}

.rbroPanelSection:hover {
    background-color: var(--rbro-detailPanel-section-hover-color);
}

.rbroPanelSection.rbroPanelSectionHeaderOpen {
    border-bottom: none;
}

.rbroPanelSection .rbroPanelSectionHeader {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 18px;
    color: var(--rbro-detailPanel-sectionHeader-color);
}

.rbroPanelSection .rbroPanelSectionHeader > span {
    margin: 0 5px;
}

.rbroFormRow.rbroError {
    background-color: #FFCCCC;
    align-items: flex-start;
}

/* Document Panel - Template layouting and PDF preview */

.rbroDocumentPanel {
    padding-top: 10px;
    right: 0;
    border: 2px solid #E4E7EA;
}

.rbroHasTabs.rbroDocumentPanel {
    padding: 0;
}

.rbroDocument {
    background-color: #FFFFFF;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    transform-origin: top left;
}

.rbroDocumentContent {
    position: absolute;
}

.rbroDocumentGrid {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAEklEQVQYlWNgYGD4zzAKBh8AAJYTAQA4FYKbAAAAAElFTkSuQmCC);
    background-repeat: repeat;
    position: absolute;
}

.rbroDocumentPreview {
    height: calc(100% - 50px);
    width: 100%;
    position: absolute;
    top: 45px;
    left: 0;
    z-index: -1;
}

.rbroDocumentBandDescription {
    display: none;
    pointer-events: none;
}

.rbroElementDragOver {
    background-color: rgba(204,204,204,0.2);
}

.rbroElementDragOver > .rbroDocumentBandDescription, .rbroHighlightBandDescription > .rbroDocumentBandDescription {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 32px;
    font-weight: 600;
    color: rgba(0,72,100,0.4);
}

.rbroHighlightBandDescription > .rbroDocumentBandDescription {
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 18px;
    background-color: rgba(204,204,204,0.2);
}

/* Document Panel - Tabs to switch between layout and preview */
.rbroDocumentTabs {
    text-align: center;
}

.rbroTabButton {
    height: 45px;
    padding: 12px 14px;
    text-align: center;
    border-right: 1px solid #E4E7EA;
    font-size: 16px;
    font-weight: 400;
    color: var(--rbro-primary-text-color);
    background-color: #F1F3F4;
    position: relative;
}

.rbroTabButton.rbroActive {
    background-color: #FFFFFF;
    color: var(--rbro-detailPanel-button-color);
    cursor: text;
}

.rbroTabButton > span {
    color: var(--rbro-primary-text-color);
    cursor: pointer;
    font-weight: 400;
    position: absolute;
    padding: 9px;
}

.rbroTabButton:not(.rbroActive):hover {
    background-color: #F1F3F4;
    color: var(--rbro-detailPanel-button-color);
}

.rbroTabButton .rbroIcon-cancel {
    top: -4px;
    right: -4px;
}

.rbroTabButton.rbroPdfPreview.rbroXlsxDownload {
    padding-right: 85px;
}

.rbroTabButton.rbroPdfPreview {
    padding-right: 35px;
}

.rbroXlsxDownloadButton {
    font-size: 26px;
    top: 0;
    right: 30px;
}

.rbroTabButton .rbroIcon-cancel:hover, .rbroXlsxDownloadButton:hover {
    color: var(--rbro-detailPanel-button-color);
}

/* Document Panel - Elements */
.rbroSectionElement {
    position: absolute;
    box-sizing: border-box;
}

.rbroSectionBandElement {
    position: absolute;
}

.rbroDocElement {
    position: absolute;
    box-sizing: border-box;
}

.rbroDocElement.rbroSelected {
    cursor: move;
}

.rbroContentContainerHelper {
    overflow: hidden;
    position: absolute;
    left: -1px;
    top: -1px;
    display: flex;
}

.rbroFrameElement .rbroContentContainerHelper {
    overflow: initial;
}

.rbroImageElement .rbroContentContainerHelper {
    height: calc(100% + 2px);
    width: calc(100% + 2px);
}

.rbroDocElementContentText {
    white-space: pre-wrap;
    overflow: hidden;
    display: flex;
}

.rbroDocElementContentText span {
    width: 100%;
}

.rbroDocElementAlignCenter, .rbroDocElementAlignCenter .rbroDocElementContentText {
    justify-content: center;
}

.rbroDocElementAlignLeft,  .rbroDocElementAlignLeft .rbroDocElementContentText {
    justify-content: flex-start;
}

.rbroDocElementAlignRight, .rbroDocElementAlignRight .rbroDocElementContentText {
    justify-content: flex-end;
}

.rbroDocElementAlignJustify,  .rbroDocElementAlignJustify .rbroDocElementContentText {
    justify-content: flex-start;
}

.rbroDocElementVAlignTop, .rbroDocElementVAlignTop .rbroDocElementContentText {
    align-items: flex-start;
}

.rbroDocElementVAlignMiddle, .rbroDocElementVAlignMiddle .rbroDocElementContentText {
    align-items: center;
}

.rbroDocElementVAlignBottom, .rbroDocElementVAlignBottom .rbroDocElementContentText {
    align-items: flex-end;
}

.rbroTextElement, .rbroImageElement, .rbroFrameElement {
    border: 1px dotted #8C8C8C;
}

.rbroTableElement table, .rbroTableElement table thead, .rbroTableElement table tbody, .rbroTableElement table tfoot, .rbroTableElement table tr,
.rbroTableElement table th, .rbroTableElement table td {
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
    margin: 0;
    padding: 0;
    min-width: 0;
    min-height: 0;
    font-weight: 400;
    color: inherit;
    box-sizing: border-box;
    background-color: transparent;
    text-align: left;
    box-shadow: none;
}

.rbroTableTextElement {
    padding: 0;
    position: relative;
}

.rbroBorderTableFrameRow .rbroTableTextElement, .rbroBorderTableRow .rbroTableTextElement,
.rbroBorderTableFrame .rbroTableTextElement, .rbroBorderTableNone .rbroTableTextElement {
    border: 1px dotted #C5C5C5 !important;
}

.rbroBorderTableFrameRow .rbroTableTextElement.rbroSelected, .rbroBorderTableRow .rbroTableTextElement.rbroSelected,
.rbroBorderTableFrame .rbroTableTextElement.rbroSelected, .rbroBorderTableNone .rbroTableTextElement.rbroSelected,
.rbroBorderTableGrid .rbroTableTextElement.rbroSelected {
    border: 1px solid #C5C5C5 !important;
}

.rbroTextElement.rbroSelected, .rbroImageElement.rbroSelected, .rbroBarCodeElement.rbroSelected,
.rbroFrameElement.rbroSelected {
    border: 1px solid #C5C5C5;
}

.rbroPageBreakElement {
    background-color: #000000;
}

.rbroSelectionArea {
    position: absolute;
    border: 1px solid #000000;
}

/* Rich text layout styles */
.rbroDocElementContentText p {
    margin: 0;
    padding: 0;
}

.rbroDocElementContentText a {
    color: inherit;
}

.rbroDocElementContentText p.ql-align-center {
    text-align: center;
}

.rbroDocElementContentText p.ql-align-right {
    text-align: right;
}

.rbroDocElementContentText p.ql-align-justify {
    text-align: justify;
    white-space: initial;
}

/* Document template styling, element resizer  */
.rbroDocumentBand {
    position: absolute;
    width: 100%;
}

.rbroDivider {
    position: absolute;
    overflow: hidden;
}

.rbroDividerMarginLeft, .rbroDividerMarginRight {
    top: 0;
    bottom: 0;
    border-left: 1px dashed #8C8C8C;
}

.rbroDividerMarginTop, .rbroDividerMarginBottom, .rbroDividerFooter, .rbroDividerHeader {
    left: 0;
    right: 0;
    border-top: 1px dashed #8C8C8C;
}

.rbroDividerSection, .rbroDividerSectionBand {
    left: 0;
    right: 0;
}

.rbroDividerSection {
    border-top: 1px solid #95badc;
}

.rbroDividerSectionBand {
    border-top: 1px dashed #95badc;
}

.rbroSizer {
    width: 7px;
    height: 7px;
    position: absolute;
    border: 1px solid #808080;
    background-color: #F1F3F4;
}

.rbroSizer.rbroSizerMove {
    cursor: move;
}

.rbroSizerN {
    top:-5px;
    left: calc(50% - 5px);
    cursor: ns-resize;
}

.rbroSizerNE {
    top: -5px;
    right: -5px;
    cursor: nesw-resize;
}

.rbroSizerE {
    top: calc(50% - 5px);
    right: -5px;
    cursor: ew-resize;
}

.rbroSizerSE {
    bottom: -5px;
    right: -5px;
    cursor: nwse-resize;
}

.rbroSizerS {
    bottom: -5px;
    left: calc(50% - 5px);
    cursor: ns-resize;
}

.rbroSizerSW {
    bottom: -5px;
    left: -5px;
    cursor: nesw-resize;
}

.rbroSizerW {
    top: calc(50% - 5px);
    left: -5px;
    cursor: ew-resize;
}

.rbroSizerNW {
    top: -5px;
    left: -5px;
    cursor: nwse-resize;
}

/* Buttons */

.rbroButton, button.rbroButton {
    cursor: pointer;
    transition: all 0.2s linear;
    display: inline-block;
    box-sizing: border-box;
    border: inherit;
}

.rbroMenuItem .rbroButton {
    min-width: 18px;
}

button.rbroActionButton {
    background-color: #E4E7EA;
    color: var(--rbro-primary-text-color);
    font-size: 16px;
    font-weight: 400;
    padding: 6px 11px;
    margin: 0 2px 0 0;
    border: 2px solid transparent;
    border-radius: 4px;
}

button.rbroActionButton:hover, button.rbroActionButton.rbroButtonActive {
    background-color: rgba(0,0,0,0.15);
}

button.rbroActionButton.rbroButtonActive {
    border: 2px solid var(--rbro-active-button-color);
}

.rbroMenuButton {
    height: 45px;
    padding: 12px 13px;
    text-align: center;
    border-right: 1px solid #E4E7EA;
    font-size: 16px;
    font-weight: 400;
    color: var(--rbro-primary-text-color);
    background-color: #FFFFFF;
}

.rbroMenuButton:not(.rbroActive):hover {
    background-color: #F1F3F4;
    color: var(--rbro-detailPanel-button-color);
}

.rbroMenuButton span {
    margin: 0 1px;
}

button.rbroButton[disabled], button.rbroButton[disabled]:hover,
button.rbroMenuButton[disabled], button.rbroMenuButton[disabled]:hover,
.rbroButton.rbroDisabled, .rbroButton.rbroDisabled:hover {
    color: #C5C5C5;
    background-color: #FFFFFF;
    cursor: not-allowed;
    border-color: #E4E7EA;
}

.rbroDeleteButton {
    color: var(--rbro-mainPanel-item-color);
}

.rbroMenuItem.rbroError .rbroDeleteButton {
    color: var(--rbro-primary-text-color);
}

.rbroDeleteButton:hover, .rbroMenuItem.rbroError .rbroDeleteButton:hover {
    color: var(--rbro-error-text-color);
}

.rbroRoundButton {
    border-radius: 50%;
    color: #FFFFFF;
    background-color: var(--rbro-detailPanel-button-color);
    font-size: 14px;
    padding: 5px;
    border: none;
}

.rbroRoundButton:hover{
    background-color: var(--rbro-detailPanel-button-hover-color);
    color: #FFFFFF;
}

.rbroMenuItem .rbroRoundButton {
    background-color: var(--rbro-mainPanel-button-color);
}

.rbroMenuItem .rbroRoundButton:hover {
    background-color: var(--rbro-mainPanel-button-hover-color);
}

/* Common */
.rbroColumnCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rbroErrorMessage {
    color: var(--rbro-error-text-color);
}

.rbroErrorMessage:empty {
    display: none;
}

.rbroBackgroundOverlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.85);
}

.rbroHidden {
    display: none !important;
}

.rbroFixedBackground {
    overflow: hidden;
}

.rbroLoadingIndicator {
	background-color: rgba(255,255,255,0.9);
    background-image: url("ajaxload.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px auto;
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
	z-index: 100000;
}

/* Drag & drop */

.rbroDropTarget * { /* prevent dragleave from firing when dragging into a child element */
    pointer-events: none;
}

/* Popup Window */
.rbroPopupWindow {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    position: absolute;
    background-color: #FFFFFF;
    color: var(--rbro-primary-text-color);
    box-sizing: border-box;
    border: 2px solid var(--rbro-popup-main-color);
    z-index: 999999;
    border-radius: 4px;
}

.rbroPopupWindowContent {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.rbroPopupWindowCancel {
    position: absolute;
    top: -13px;
    right: -13px;
    background-color: var(--rbro-detailPanel-button-color);
}

.rbroPopupWindow ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.rbroPopupWindow li {
    list-style-type: none;
    margin: 0;
    padding: 4px 10px;
    display: block;
}

.rbroPopupWindow li:not(.rbroPopupItemSeparator) {
    cursor: pointer;
}

.rbroPopupWindow li:not(.rbroPopupItemSeparator):hover {
    background-color: #F1F3F4;
}

.rbroPopupWindow .rbroPopupItemSeparator {
    text-align: center;
    background-color: var(--rbro-popup-item-bg-color);
    color: var(--rbro-popup-item-text-color);
    font-weight: 600;
    padding: 8px 10px;
}

.rbroPopupWindow .rbroPopupItemSeparator.rbroParameterGroup {
    background-color: var(--rbro-popup-item-alt-bg-color);
}

.rbroPopupWindow .rbroPopupItemHeader {
    font-size: 13px;
}

.rbroPopupWindow .rbroPopupItemDescription {
    font-size: 11px;
    color: var(--rbro-popup-description-color);
}

.rbroPopupWindowContent table, .rbroPopupWindowContent table thead, .rbroPopupWindowContent table tbody,
.rbroPopupWindowContent table tr, .rbroPopupWindowContent table th, .rbroPopupWindowContent table td {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    text-align: left;
    font-weight: 400;
    color: inherit;
    box-sizing: border-box;
    box-shadow: none;
    position: relative;
    top: 0;
    left: 0;
    vertical-align: middle;
}

.rbroPopupWindowContent table {
    width: 98%;
    margin: 10px auto 0;
}

.rbroPopupWindowContent table table {
    /* nested table */
    margin: 0 0 0 auto;
}

.rbroPopupWindowContent table .rbroFullWidthButton {
     /* align button with nested table */
    margin: 0 0 10px 50px;
}

.rbroPopupWindowContent table th span {
    margin: 0 5px;
}

.rbroPopupWindowContent table th:not(:first-child) {
    background-color: var(--rbro-popup-item-bg-color);
    color: var(--rbro-popup-item-text-color);
    border: 1px solid #E4E7EA;
    padding: 5px 2px;
    font-weight: 600;
    text-align: center;
}

.rbroPopupWindowContent table table th:not(:first-child) {
    background-color: var(--rbro-popup-item-alt-bg-color);
}

.rbroPopupWindowContent table th:first-child {
    width: 2%;
}

.rbroPopupWindowContent table th .rbroButton {
    margin-left: 5px;
}

.rbroPopupWindowContent table td:not(:first-child) {
    border: 1px solid #8C8C8C;
}

.rbroPopupWindowContent table input[type=text], .rbroPopupWindowContent table input[type=text]:focus,
.rbroPopupWindowContent table td .expandableCell {
    color: var(--rbro-primary-text-color);
    width: 100%;
    box-sizing: border-box;
    height: 30px;
    padding: 4px;
    margin: 0;
    border: none;
    border-radius: 0;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 1;
    background-color: transparent;
    box-shadow: none;
    outline: none;
}

.rbroPopupWindowContent table td.rbroHasFocus input[type=text] {
    border: 2px solid #009EE2;
    padding: 2px;
}

.rbroPopupWindowContent table td .expandableCell {
    cursor: pointer;
    transition: all 0.2s linear;
}

.rbroPopupWindowContent table td .expandableCell:hover,
.rbroPopupWindowContent table td .expandableCell.rbroExpandedCell {
    background-color: #F1F3F4;
}

.rbroPopupWindowContent table .rbroButton {
    vertical-align: baseline;
}

.rbroFullWidthButton {
    display: block;
    margin: 0 12px 10px 38px;
}

.rbroFullWidthButton .rbroPopupWindowButton {
    width: 100%;
}

.rbroPopupWindowButton {
    padding: 12px;
    text-align: center;
    color: var(--rbro-primary-text-color);
    background-color: #F1F3F4;
    font-size: 16px;
}

.rbroPopupWindowButton:hover {
    background-color: #E4E7EA;
}

.rbroPopupSearch, .rbroPopupSearch:focus {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    height: 32px;
    box-sizing: border-box;
    border: none;
    transition: all 0.2s linear;
    padding: 0 4px;
    outline: none;
    font-size: 12px;
    border-radius: 4px 4px 0 0 ;
    box-shadow: none;
}

.rbroPopupWindow .rbroImageFileContainer {
    display: flex;
    font-size: 12px;
    gap: 5px;
    padding: 0 4px;
}

.rbroPopupWindowContent .rbroTestDataCheckbox {
    text-align: center;
}

/* ReportBro color picker */
.rbroColorPickerContainer {
    transition: all 0.2s linear;
    background-color: #F1F3F4;
    border-radius: 20px 4px 4px 20px;
    border: 1px solid rgba(0,0,0,0.1);
    height: 28px;
    position: relative;
}

.rbroColorPickerContainer.rbroActive {
    border: 1px solid var(--rbro-detailPanel-focus-color);
    background-color: #FFFFFF;
}

.rbroColorPickerContainer input, .rbroColorPickerContainer input:focus {
    height: 28px;
    margin: 0 0 0 30px;
    width: calc(100% - 30px);
    background-color: #F1F3F4;
    border: none;
    color: var(--rbro-primary-text-color);
    display: block;
    font-size: 13px;
    padding: 0 5px;
}

.rbroColorPickerContainer.rbroActive input {
    background-color: #FFFFFF;
}

.rbroColorPicker {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 24px;
    height: 24px;
    background-color: currentColor;
    border-radius: 100%;
    cursor: pointer;
}

.rbroColorPicker.rbroTransparentColorSelect {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
}

.rbroColorPalette {
    background-color: #F1F3F4;
    border: 1px solid #F1F3F4;
    border-radius: 4px;
    position: absolute;
    padding: 3px 5px;
    width: 172px;
    box-shadow: rgba(0,0,0,0.2) 0 2px 8px;
    box-sizing: border-box;
    z-index: 1;
}

.rbroColorPalette .rbroColorPaletteItem {
    float: left;
    height: 16px;
    margin: 2px;
    padding: 0;
    width: 16px;
    border: 2px solid transparent;
    cursor: pointer;
    display: block;
    border-radius: 100%;
    overflow: hidden;
    box-sizing: border-box;
    background-color: currentColor;
}

.rbroColorPalette .rbroColorPaletteItem:hover {
    border: 2px solid var(--rbro-popup-main-color);
}

.rbroColorPalette .rbroClearColorPalette {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 2px;
    text-align: center;
}
